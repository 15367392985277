@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,400;0,700;1,400&display=swap');

.mainContent {
    background-color: white;
}

.articleTitle {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: 'Source Sans 3', sans-serif;
}

.articleContent {
    font-family: 'Source Sans 3', sans-serif;
}

.articleContent p,
.articleContent ul,
.articleContent ol {
    line-height: 1.6;
    margin-bottom: 16px;
    letter-spacing: 0.2px;
}

.articleContent ol,
.articleContent ul {
    padding-left: 20px;
}

.articleContent h1,
.articleContent h2,
.articleContent h3,
.articleContent h4,
.articleContent h5,
.articleContent h6 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-family: 'Source Sans 3', sans-serif;
}

.articleContent a {
    color: #0056b3;
    text-decoration: none;
}

.articleContent a:hover {
    text-decoration: underline;
}

.articleContent img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px auto;
}

@media (max-width: 768px) {
    .articleTitle {
        font-size: 24px;
    }

    .articleContent img {
        width: 100%;
        height: auto;
    }
}