.notification {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4CAF50;
    /* Default success green */
    color: white;
    padding: 16px;
    border-radius: 8px;
    z-index: 9999;
    font-size: 16px;
    font-family: Arial, sans-serif;
    text-align: center;
    min-width: 250px;
    opacity: 0.9;
    transition: opacity 0.5s ease;
}

/* Error notification style */
.notification.error {
    background-color: #f44336;
    /* Error red */
}

/* Success notification style */
.notification.success {
    background-color: #4CAF50;
    /* Success green */
}