.newsletter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.newsletter-title-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.newsletter-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    margin-left: 10px;
}

.newsletter-subscribe-button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
}

.newsletter-subscribe-button:hover {
    background-color: #003d82;
}

@media (max-width: 768px) {
    .newsletter-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .newsletter-subscribe-button {
        margin-top: 10px;
        align-self: stretch;
    }
}