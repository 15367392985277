.landing-container {
    display: grid;
    grid-template-rows: 70vh auto auto;
    min-height: 100vh;
    /* Ensure the container takes up at least the full viewport height */
    font-family: 'Inter', sans-serif;
    text-align: center;
}

@media (max-width: 768px) {
    .landing-container {
        grid-template-rows: auto 1fr auto;
    }
}

/* Header */
.landing-header {
    background: url('../assets/background.png');
    background-size: cover;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo {
    object-fit: scale-down;
    width: 50px;
    height: 50px;
}

.landing-header h1 {
    font-family: 'Source Sans 3', serif;
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 20px;
}

.landing-header p {
    font-family: 'Source Sans 3', serif;
    font-size: 18px;
    color: #4B5563;
    margin-bottom: 30px;
    max-width: 600px;
}

.download-buttons {
    display: flex;
    gap: 10px;
}

.app-store-link,
.google-play-link {
    width: 168px;
    height: 48px;
    object-fit: scale-down;
}

@media (max-width: 768px) {
    .landing-header h1 {
        font-size: 40px;
    }

    .landing-header p {
        font-size: 16px;
    }
}

/* Learn more */
.learn-more {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
}

.learn-more h2 {
    font-size: 18px;
    color: #4B5563;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 20px;
}

.learn-more-button-group {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}

.learn-more-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 5px 20px;
    background-color: #f1f1f1;
    color: #333;
    border-radius: 20px;
    text-decoration: none;
    font-size: 1rem;
    border: 1px solid #E5E7EB;
    transition: background-color 0.3s ease;
}

.learn-more-button:hover {
    background-color: #e2e2e2;
}

.learn-more-button .icon {
    font-size: 1.2rem;
}

/* Footer */
.landing-footer {
    background: #1f1f1f;
    color: white;
    padding: 20px 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.landing-footer p {
    font-size: 16px;
    color: #fff;
    text-align: start;
}

.footer-links {
    display: flex;
    gap: 15px;
    margin-bottom: 0;
    /* Remove margin for correct alignment */
}

.footer-links a {
    color: white;
    text-decoration: none;
}

.social-links {
    display: flex;
    gap: 10px;
}

.social-links a {
    color: white;
    text-decoration: none;
}

@media (max-width: 768px) {
    .landing-footer {
        padding: 20px 20px;
    }
}