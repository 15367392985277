.issue-card {
    display: flex;
    padding: 5px;
    background: white;
    min-height: 105px;
    cursor: pointer;
    border-bottom: 0.5px solid #E5E7EA;
}

.issue-card.share-modal-style {
    cursor: default;
    /* Remove pointer cursor in Share Modal */
    border-bottom: none;
    /* Remove underline in Share Modal */
}

.issue-image img,
.issue-image .placeholder-image {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    margin-right: 20px;
    object-fit: cover;
}

.issue-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
}

.issue-content h2 {
    margin: 0;
    font-size: 18px;
    font-family: 'Source Sans 3', sans-serif;
    letter-spacing: 0.2px;
}

.issue-content p {
    margin: 10px 0 0;
    font-size: 14px;
    color: #666;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 3.6em;
    line-height: 1.2em;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2px;
}