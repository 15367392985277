.newsletter-container {
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Source Sans 3', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Full viewport height */
}

.newsletter-sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  padding: 20px 20px 0;
}

.newsletter-content-wrapper {
  padding: 0 20px 20px;
  /* Remove overflow-y and flex-grow */
}

.newsletter-loading {
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
}

@media (max-width: 768px) {

  .newsletter-sticky-header,
  .newsletter-content-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}