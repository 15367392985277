.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 0px;
}

.modal-content-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.modal-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}

.author-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.modal-newsletter-title {
  text-align: center;
  color: #000;
  margin: 0px;
  /* margin-top: 20px;
  margin-bottom: 15px; */
  font-size: 20px;
}

.modal-title {
  text-align: center;
  color: #000;
  margin: 0px;
  font-weight: 700;
  /* margin-top: 20px;
  margin-bottom: 15px; */
  font-size: 16px;
}

.modal-subtitle {
  text-align: center;
  color: #666;
  margin: 0px;
  /* margin-bottom: 15px; */
  font-size: 14px;
}

.subscriber-count {
  text-align: center;
  color: #666;
  margin: 0px;
  /* margin-bottom: 15px; */
  font-size: 14px;
}

.email-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.subscribe-button {
  width: 100%;
  padding: 10px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  /* margin-bottom: 15px; */
}

.subscribe-button:hover {
  background-color: #003d82;
}

.continue-reading {
  width: 100%;
  background: none;
  border: none;
  color: #666;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  margin-left: 5px;
  font-size: 20px;
}