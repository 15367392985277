.center-aligned-container {
    /* min-height: calc(100vh - 20px); */
    /* height: calc(100% - 20px); */
    /* max-height: calc(100% - 20px); */
    /* overflow-y: auto; */
    /* box-sizing: border-box; */
    width: calc(100vw - 20px);
    gap: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.container-title {
    margin: 0px;
    text-align: center;
}

.buttons-container {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(2, 260px);
    gap: 40px;
    width: 100%;
}

/* Media query for screens smaller than 450px */
@media screen and (max-width: 580px) {
    .buttons-container {
        grid-template-columns: repeat(auto-fit, 260px);
    }
}

button {
    font-size: 18px;
    color: #0066cc;
    background: none;
    border: none;
    margin-bottom: 30px;
    cursor: pointer;
    text-align: left;
    padding: 0;
}

button:disabled {
    cursor: not-allowed;
}

.action-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 10px;
    width: 200px;
    padding: 15px 30px 15px 30px;
    margin: 0px;
    border-radius: 40px;
    background-color: #06ACF4;
}

.linkedin-button {
    background-color: #0A66C2;
}

.x-button {
    background-color: #000000;
}

.medium-button {
    background-color: #000000;
}

.reddit-button {
    background-color: #FF6101;
}

.custom-button {
    background-color: #4CAF50;
}

.custom-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.action-button-image {
    width: 20px;
    height: 20px;
}

.action-button {
    margin: 0px;
    padding: 0px;
    color: white;
}

.text-view {
    width: 60%;
    /* height: 200px; */
    min-height: 200px;
    padding: 15px;
    border: 2px solid #06ACF4;
    border-radius: 10px;
    font-size: 16px;
    resize: vertical;
    font-family: Arial, sans-serif;
    outline: none;
}

.text-view:focus {
    border-color: #0099cc;
    box-shadow: 0 0 10px rgba(0, 153, 204, 0.2);
}

.input-range-container {
    display: flex;
    flex-direction: column;
    width: 60%
}

/* Slider container */
input[type="range"] {
    width: 100%;
    margin: 10px 0;
}

/* Slider styling */
input[type="range"]::-webkit-slider-runnable-track {
    background: #06ACF4;
    height: 6px;
    border-radius: 4px;
}

input[type="range"]::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid #06ACF4;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -7px;
    position: relative;
    z-index: 10;
}

input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid #06ACF4;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
}

.custom-type-container {
    margin: 0;
    text-align: center;
}

.custom-type-input {
    width: 80%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}