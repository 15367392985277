.unsubscribe-container {
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Source Sans 3', sans-serif;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.unsubscribe-header {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.unsubscribe-message {
    font-size: 18px;
    margin-bottom: 30px;
    text-align: center;
}

.unsubscribe-button {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.unsubscribe-button:hover {
    background-color: #c9302c;
}

.unsubscribe-status {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
}

.unsubscribe-status.success {
    color: green;
}

.unsubscribe-status.error {
    color: red;
}

@media (max-width: 768px) {
    .unsubscribe-container {
        padding: 10px;
    }

    .unsubscribe-header {
        font-size: 24px;
    }

    .unsubscribe-message {
        font-size: 16px;
    }

    .unsubscribe-button {
        padding: 10px 20px;
        font-size: 14px;
    }
}