.newsletter-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.newsletter-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.newsletter-logo-placeholder {
    width: 100%;
    height: 100%;
    background-color: #0056b3;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
}