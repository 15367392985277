/* Overlay and Modal Container */
.share-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.share-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* Header and Close Button */
.share-modal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.share-modal-header {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    text-align: left;
    flex: 1;
    margin: 0;
}

.share-modal-close {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 20px;
}

.close-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

/* Modal Preview Section */
.share-modal-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.share-modal-logo {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    object-fit: contain;
}

.share-modal-url {
    font-size: 16px;
    color: #666;
    text-align: center;
}

/* Share Option Buttons */
.share-modal-options {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    gap: 20px;
}

.share-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
}

.icon-button {
    background-color: white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 8px;
    /* Adjusted padding to better center the 16x16 icon */
    margin-bottom: 10px;
    cursor: pointer;
    width: 48px;
    /* Updated button width */
    height: 48px;
    /* Updated button height */
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.icon-image {
    width: 20px;
    /* Updated icon width */
    height: 20px;
    /* Updated icon height */
    object-fit: contain;
}

.share-option p {
    margin: 0;
    font-size: 14px;
    color: #333;
}