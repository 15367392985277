/* NewsletterPage.css */
.newsletter-page {
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Source Sans 3', sans-serif;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.newsletter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.subscribe-button {
    padding: 10px 20px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.issues-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}